var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Lib
import React, { lazy, Suspense } from "react";
var Select = lazy(function () { return import('react-select'); });
// Components
import Checkboxes from "./Checkboxes";
var CuisineSelection = /** @class */ (function (_super) {
    __extends(CuisineSelection, _super);
    function CuisineSelection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            options: [],
            selectedOptions: [],
            primarySelection: null
        };
        _this.isPrimarySelected = function (value) {
            return (_this.props.form.values[_this.props.field.name].primary !== null &&
                "".concat(_this.props.form.values[_this.props.field.name].primary.value) === "".concat(value));
        };
        _this.isChecked = function (value) {
            var _a;
            var selected = (_a = _this.props.form.values[_this.props.field.name].secondary) !== null && _a !== void 0 ? _a : [];
            return !!selected.filter(function (selectedOption) { return "".concat(selectedOption.value) === "".concat(value); }).length;
        };
        _this.getCheckboxClass = function (value) {
            var classes = ["checkbox__control"];
            if (_this.isPrimarySelected(value)) {
                classes.push("checkbox__control--selected");
            }
            return classes.join(' ');
        };
        _this.setFormFromState = function () {
            _this.props.form.setFieldValue(_this.props.field.name, {
                primary: _this.state.primarySelection,
                secondary: _this.state.selectedOptions
            });
        };
        _this.setPrimary = function (selected) {
            _this.setState({
                primarySelection: selected,
                selectedOptions: _this.state.selectedOptions.filter(function (value) { return "".concat(value.value) !== "".concat(selected.value); })
            }, _this.setFormFromState);
        };
        _this.updateState = function (value) {
            _this.setState({ selectedOptions: value }, _this.setFormFromState);
        };
        _this.renderInput = function (option) {
            return (React.createElement("div", { className: "checkbox", key: "".concat(_this.props.taxonomySlug, "-").concat(option.value) },
                React.createElement("input", { id: "".concat(_this.props.taxonomySlug, "-").concat(option.value), className: _this.getCheckboxClass(option.value), value: option.value, type: "checkbox", disabled: _this.isPrimarySelected(option.value), checked: _this.isChecked(option.value), onChange: _this.triggerCheckboxChange }),
                React.createElement("label", { htmlFor: "".concat(_this.props.taxonomySlug, "-").concat(option.value), className: "checkbox__label" }, option.label)));
        };
        _this.render = function () {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "form__group" },
                    React.createElement("label", { className: "form__label", htmlFor: "taxonomy-" + _this.props.taxonomySlug }, "Primary Cuisine *"),
                    React.createElement("div", { className: "select-wrapper" },
                        React.createElement(Suspense, { fallback: React.createElement("div", { className: "form__control" }) },
                            React.createElement(Select, { id: "taxonomy-" + _this.props.taxonomySlug, options: _this.state.options, isDisabled: !_this.state.options.length, onChange: _this.setPrimary, isMulti: false, cacheOptions: true, placeholder: "Select the main cuisine for your venue", value: _this.state.primarySelection, className: "react-select", classNamePrefix: "react-select" })))),
                React.createElement("div", { className: "form__group" },
                    React.createElement("label", { className: "form__label", htmlFor: "cuisineType" }, "Secondary Cuisine"),
                    React.createElement("div", { id: "secondaryCuisine", className: "checkboxes checkboxes--optional" }, _this.state.options.map(_this.renderInput)))));
        };
        return _this;
    }
    return CuisineSelection;
}(Checkboxes));
export default CuisineSelection;
