var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Lib
import * as React from "react";
import get from "lodash.get";
import API from "../lib/API";
var Checkboxes = /** @class */ (function (_super) {
    __extends(Checkboxes, _super);
    function Checkboxes() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.resetSelection = function () {
            _this.setState({
                selectedOptions: [],
                otherSelected: false
            });
        };
        _this.isChecked = function (value) {
            var _a;
            var selected = (_a = get(_this.props.form.values, _this.props.field.name)) !== null && _a !== void 0 ? _a : [];
            return !!selected.filter(function (selectedOption) { return "".concat(selectedOption.value) === "".concat(value); }).length;
        };
        _this.triggerCheckboxChange = function (ev) {
            _this.props.form.setFieldTouched(_this.props.field.name, true);
            if (ev.currentTarget.checked) {
                _this.addSelected(ev.currentTarget.value);
            }
            else {
                _this.removeSelected(ev.currentTarget.value);
            }
        };
        _this.addSelected = function (value) {
            var selectedOption = _this.state.options.filter(function (option) { return "".concat(option.value) === "".concat(value); });
            if (selectedOption.length) {
                _this.updateState(__spreadArray(__spreadArray([], _this.state.selectedOptions, true), [selectedOption[0]], false));
            }
        };
        _this.removeSelected = function (value) {
            _this.updateState(__spreadArray([], _this.state.selectedOptions, true).filter(function (option) { return "".concat(value) !== "".concat(option.value); }));
        };
        _this.toggleAllSelected = function () {
            if (_this.state.selectedOptions.length === _this.state.options.length) {
                _this.updateState([]);
            }
            else {
                _this.updateState(_this.state.options);
            }
        };
        _this.updateState = function (value) {
            _this.setState({ selectedOptions: value }, function () {
                _this.props.form.setFieldValue(_this.props.field.name, _this.state.selectedOptions);
            });
        };
        _this.renderInput = function (option) {
            return (React.createElement("div", { className: "checkbox", key: "".concat(_this.props.taxonomySlug, "-").concat(option.value) },
                React.createElement("input", { id: "".concat(_this.props.taxonomySlug, "-").concat(option.value), className: "checkbox__control", value: option.value, type: "checkbox", checked: _this.isChecked(option.value), onChange: _this.triggerCheckboxChange }),
                React.createElement("label", { htmlFor: "".concat(_this.props.taxonomySlug, "-").concat(option.value), className: "checkbox__label" },
                    React.createElement("span", null, option.label))));
        };
        return _this;
    }
    Checkboxes.prototype.componentDidMount = function () {
        var _this = this;
        if (typeof this.props.taxonomySlug !== 'undefined') {
            API.getTaxonomy(this.props.taxonomySlug)
                .then(function (resp) { return _this.setState({ options: resp }); });
        }
    };
    return Checkboxes;
}(React.Component));
export default Checkboxes;
